import { template as template_a750fd7b3bcb4e36a95700fe3fdd0cae } from "@ember/template-compiler";
const FKText = template_a750fd7b3bcb4e36a95700fe3fdd0cae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
