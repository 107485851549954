import { template as template_b8e1febfa6244263b8a8121c86973c3b } from "@ember/template-compiler";
const WelcomeHeader = template_b8e1febfa6244263b8a8121c86973c3b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
