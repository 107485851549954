import { template as template_939d15d34af2436cb50f82e2b8b43927 } from "@ember/template-compiler";
const FKLabel = template_939d15d34af2436cb50f82e2b8b43927(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
