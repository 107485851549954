import { template as template_1695957d318d428491bff925bc388feb } from "@ember/template-compiler";
const FKControlMenuContainer = template_1695957d318d428491bff925bc388feb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
