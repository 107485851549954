import { template as template_90f76baa78ae4482a823559a831e39e6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_90f76baa78ae4482a823559a831e39e6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
